<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        {{ $t('modules.subscriptions._plans.title') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('modules.subscriptions._plans.subtitle') }}
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          {{ $t('modules.subscriptions._messages.monthly') }}
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="togglePlan"
        />
        <h6 class="ml-50 mb-0">
          {{ $t('modules.subscriptions._messages.annually') }}
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
    >
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row>
            <b-col
              v-for="(plan) in pricing.plans"
              v-show="plan.period_unit === 'M' ? monthlyPlanShow : !monthlyPlanShow"
              :key="`pricing_plan_${plan.code}`"
              :md="12 / pricing.plans.length * 2"
            >
              <b-card
                :class="{'popular': plan.is_popular}"
                align="center"
              >
                <div class="pricing-badge d-flex justify-content-between">
                  <div
                    v-show="plan.code === pricing.meta.current"
                    class="text-left"
                  >
                    <b-badge
                      variant="light-primary"
                      pill
                    >
                      {{ $t('modules.subscriptions._messages.current_plan') }}
                    </b-badge>
                  </div>
                  <div
                    v-show="plan.is_popular"
                    class="text-right"
                  >
                    <b-badge
                      variant="light-primary"
                      pill
                    >
                      {{ $t('modules.subscriptions._messages.popular') }}
                    </b-badge>
                  </div>
                </div>
                <!-- img -->
                <b-img
                  :src="PLANS_IMAGES[plan.group]"
                  class="mb-1"
                  alt="svg img"
                />
                <!--/ img -->
                <h3>{{ plan.title }}</h3>
                <b-card-text>{{ plan.subtitle }}</b-card-text>

                <!-- annual plan -->
                <div
                  v-if="plan.is_coming_soon"
                  class="annual-plan"
                >
                  <div class="plan-price mt-2">
                    <span
                      v-t="'Soon'"
                      class="pricing-basic-value font-weight-bolder text-secondary"
                    />
                  </div>
                </div>
                <div
                  v-else-if="plan.price"
                  class="annual-plan"
                >
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">{{ plan.currency_symbol }}</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ parseFloat(plan.monthly_price / 100).toFixed(0) }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> / {{ $t('modules.subscriptions._messages.month') }}</sub>
                  </div>
                  <small
                    v-show="plan.period_unit === 'Y'"
                    class="annual-pricing text-muted"
                  >
                    <span>{{ plan.currency_symbol }}{{ parseFloat(plan.price / 100).toFixed(0) }}</span>
<!--                    <money-->
<!--                      :value="plan.price / 100"-->
<!--                      :currency="plan.currency_code"-->
<!--                      :decimals="0"-->
<!--                      :short="false"-->
<!--                      class="pr-25"-->
<!--                    />-->
                    <span> / {{ $t('modules.subscriptions._messages.year') }}</span>
                  </small>
                </div>
                <div
                  v-else
                  class="annual-plan"
                >
                  <div class="plan-price mt-2">
                    <span
                      v-t="'Free'"
                      class="pricing-basic-value font-weight-bolder text-primary"
                    />
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in plan.features"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <template
                  v-if="plan.group !== 'LITE'"
                >
                  <template v-if="plan.is_coming_soon">
                    <b-button
                      v-if="pricing.meta.current !== plan.code"
                      v-t="'Coming Soon'"
                      block
                      class="mt-2"
                      variant="outline-secondary"
                      disabled="disabled"
                    />
                  </template>
                  <template v-else>
                    <!-- buttons -->
                    <b-button
                      v-if="pricing.meta.current === plan.code"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2 add-to-cart"
                      variant="outline-success"
                      @click="onAddToCartClick(plan.code)"
                    >
                      {{ $t('modules.subscriptions.actions.purchase') }}
                    </b-button>
                    <b-button
                      v-if="pricing.meta.current !== plan.code"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      variant="primary"
                      @click="onAddToCartClick(plan.code)"
                    >
                      {{ $t('modules.subscriptions.actions.select') }}
                    </b-button>
                  </template>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <!-- TODO: Future feature -->
    <div
      v-if="0"
      class="pricing-free-trial"
    >
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? Start with a 14-day FREE trial!
              </h3>
              <h5>You will get full access to with all the features for 14 days.</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
              >
                Start 14-day FREE trial
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div class="pricing-faq pt-3">
      <h3
        v-t="'modules.subscriptions.faqs.title'"
        class="text-center"
      />
      <p
        v-t="'modules.subscriptions.faqs.subtitle'"
        class="text-center"
      />
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(faq,index) in faqs"
              :key="index"
              :title="faq.q"
            >
              {{ faq.a }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onMounted, onUnmounted, ref } from 'vue'
import subscriptionStoreModule from '@/views/models/subscriptions/subscriptionStoreModule'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import Router from '@/router'
import { toast } from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
/* eslint-disable global-require */
const PLANS_IMAGES = {
  LITE: require('@/assets/images/illustration/Pot1.svg'),
  BASIC: require('@/assets/images/illustration/Pot2.svg'),
  PRO: require('@/assets/images/illustration/Pot3.svg'),
}
export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      PLANS_IMAGES,
      faqs: [
        {
          q: this.$t('modules.subscriptions.faqs.faq_1.q'),
          a: this.$t('modules.subscriptions.faqs.faq_1.a'),
        },
        {
          q: this.$t('modules.subscriptions.faqs.faq_2.q'),
          a: this.$t('modules.subscriptions.faqs.faq_2.a'),
        },
        {
          q: this.$t('modules.subscriptions.faqs.faq_3.q'),
          a: this.$t('modules.subscriptions.faqs.faq_3.a'),
        },
      ],
    }
  },
  methods: {
    togglePlan() {
      this.monthlyPlanShow = this.status === 'monthly'
    },
  },
  setup() {
    const SUBSCRIPTIONS_STORE_MODULE_NAME = 'subscriptions'
    const PURCHASES_STORE_MODULE_NAME = 'purchases'

    // Register module
    if (!store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTIONS_STORE_MODULE_NAME, subscriptionStoreModule)
    if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    onMounted(() => {
      if (!store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTIONS_STORE_MODULE_NAME, subscriptionStoreModule)
      if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.unregisterModule(SUBSCRIPTIONS_STORE_MODULE_NAME)
      if (store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.unregisterModule(PURCHASES_STORE_MODULE_NAME)
    })

    const isLoading = ref(true)
    const pricing = ref({})
    const fetchItemsList = () => {
      // ctx.refs.preview.reset()
      isLoading.value = true
      store.dispatch(`${SUBSCRIPTIONS_STORE_MODULE_NAME}/fetchPlans`)
        .then(response => {
          isLoading.value = false
          pricing.value = {
            plans: response.data.data,
            meta: response.data.meta,
          }
        })
    }
    fetchItemsList()

    // TODO: Move to a common file as this method is duplicated
    const onAddToCartClick = purchasableCode => {
      if (isLoading.value) {
        return
      }
      isLoading.value = true
      store.dispatch(`${PURCHASES_STORE_MODULE_NAME}/updateAttribute`, {
        itemId: 'cart',
        attribute: 'items',
        value: [{
          id: purchasableCode,
          qty: 1,
        }],
      })
        .then(response => {
          Router.push({ name: 'purchases.checkout' })
        })
        .catch(e => {
          const { t } = useI18nUtils()
          const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
          console.error(e)
          toast('danger', title, '', 'AlertTriangleIcon')
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      isLoading,
      pricing,
      onAddToCartClick,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
